<template>
  <section class="box">
    <div class="productCardTop">
      <router-link :to="`/product/${item._id}`">
        <img :src="item.medias[0].mediaLink" alt="" />
      </router-link>

      <v-icon
        class="heart"
        v-if="!loading && !item.wishlist"
        @click="addToWishlist"
      >
        mdi-heart-outline
      </v-icon>
      <v-icon
        class="heart filled"
        v-if="!loading && item.wishlist"
        @click="addToWishlist"
      >
        mdi-heart
      </v-icon>
      <v-progress-circular
        indeterminate
        v-if="loading"
        size="23"
        class="heart loadingIcon"
        width="2"
      ></v-progress-circular>
    </div>
    <div class="productCardBody">
      <router-link :to="`/product/${item._id}`">
        <h4>{{ item.productName }}</h4>
      </router-link>
      <span class="rating">
        <v-rating
        readonly
          :value="item.rating"
          class="mr-2"
          size="20"
          color="orange"
          background-color="orange"
        ></v-rating>
      </span>
      <h2>Rs. {{ item.price }}</h2>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "WishlistBox",
  props: ["item"],
  data: () => ({
    loading: false,
  }),
  methods: {
    ...mapActions(["postWishlist"]),
    async addToWishlist() {
      this.loading = true;
      await this.postWishlist({ productId: this.item._id });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  border: 2px solid #ededed;
  border-radius: 8px;
  overflow: hidden;
}
.borderBox {
  border-bottom: 1px solid #ededed;
  display: block;
}
img {
  width: 100%;
  object-fit: cover;
  height: 250px;
}
a {
  text-decoration: none;
  color: #000;
}
.productCardBody {
  padding: 15px;
  margin-top: 10px;
  h2 {
    margin-bottom: 10px;
    font-size: 18px;
  }
  hr {
    border-bottom: #fff;
  }
  .footer {
    list-style: none;
    padding-top: 10px;
    display: flex;
    padding-left: 0;
    font-size: 14px;
    li {
      margin-right: 10px;
    }
  }
}
.rating {
  margin: 2px 0 5px;
  display: flex;
  font-size: 14px;
  align-items: center;
  margin-left: -5px;
}
.productCardTop {
  position: relative;
  height: 250px;

  .heart {
    position: absolute;
    right: 15px;
    top: 15px;
    color: #000;
    padding: 3px;
    border-radius: 50%;
    background: #fff;
  }
  .filled{
    color:red
  }
}
</style>