<template>
  <section>
    <section class="mt-5 my-15">
      <v-container class="container-custom">
        <v-row>
          <v-col cols="12">
            <div>
              <h2 class="text-left">Saved Products</h2>

              <v-row v-if="!loading">
                <v-col
                  md="3"
                  v-for="(data, index) in allWishlists.results"
                  :key="index + 'wishlist'"
                >
                  <WishlistBox :item="data" />
                </v-col>
              </v-row>

              <v-row v-if="!loading && allWishlists.results.length == 0">
                <v-col>
                  <p>Not Saved Product Found</p>
                </v-col>
              </v-row>
              <v-row v-if="loading">
                <v-col md="3">
                  <LoadingSkeleton :image="2" :title="2" :text="6" />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import WishlistBox from "@/components/WishlistBox";
import LoadingSkeleton from "@/components/LoadingSkeleton";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SavedCars",
  data: () => ({
    loading: true,
    products: [],
  }),
  methods: {
    ...mapActions(["getWishList"]),
  },
  computed: {
    ...mapGetters(["allWishlists"]),
  },
  components: {
    WishlistBox,
    LoadingSkeleton,
  },
  async created() {
    await this.getWishList();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.cateogyIcon {
  display: flex;
  justify-content: space-between;
  margin-left: 0;
  padding-left: 0;
  margin: 0 -25px;
  width: 100%;
  li {
    margin: 20px;
    height: 200px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: #efefef;
    width: 100%;
    border-radius: 10px;
    img {
      width: 80px;
    }
  }
}
.backG {
  background: #f9f9f9;
  padding: 30px 0;
  .v-btn {
    margin-top: 20px;
  }
}
img {
  width: 100%;
}
.brandFilter {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 40px;
  li {
    padding: 0 20px;
    list-style: none;
  }
}
.companyLogo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}
h2 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 30px;
}
.advertiesmentSection {
  height: 550px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    position: absolute;
  }
  h2 {
    text-align: center;
    margin-bottom: 0;
    z-index: 9;
    color: #fff;
    width: 500px;
    margin-bottom: 20px;
    font-size: 50px;
  }
  p {
    text-align: center;
    z-index: 9;
    position: relative;
    color: #fff;
    width: 500px;
    font-size: 18px;
    line-height: 32px;
  }
}
.advertiesmentBoxes {
  position: relative;
  margin-top: -120px;
  img {
    width: 100%;
    height: 350px;
  }
}

.relBox {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .content {
    position: relative;
    z-index: 99;
    padding: 0 90px;
    h1 {
      font-size: 40px;
      color: #fff;
      margin-bottom: 15px;
    }
    p {
      font-size: 20px;
      width: 60%;
      color: #fff;
      margin-bottom: 30px;
    }
  }
  position: relative;
  overflow: hidden;
  height: 450px;
  background: #0878d4;
  .backImage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
.maringTB {
  margin-top: 30px;
  margin-bottom: 90px;
  .v-btn {
    min-height: 60px;
    width: 180px;
  }
}
.blueBtn {
  background: #0878d4 !important;
  font-size: 16px;
  .v-icon {
    font-size: 20px;
    margin-left: 10px;
  }
}
.whiteBtn {
  background: #fff !important;
  color: #0878d4 !important;
  font-size: 16px;
  .v-icon {
    font-size: 20px;
    margin-left: 10px;
  }
}
.downloadBtn {
  list-style: none;
  display: flex;
  padding-left: 0;
  height: 50px;
  img {
    width: 180px;
    height: 60px;
  }
  li {
    margin-right: 10px;
    img {
      margin-top: 10px;
    }
  }
}
.leftSection {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: left;
  padding-left: 30px;
  h2 {
    text-align: left;
    margin-bottom: 10px;
    line-height: 60px;
    font-size: 50px;
    color: #fff;
  }
  p {
    margin-bottom: 20px;
    color: #fff;
  }
}
.backBG {
  background: #0878d4;
  overflow: hidden;
  padding: 0 40px;
  img {
    margin-top: 80px;
    margin-bottom: -20px;
  }
}
.gradientBack {
  background: linear-gradient(to top, #f4f4f4 50%, white 50%);
  margin-top: 50px;
}
.v-btn {
  min-height: 60px;
  width: 180px;
}
</style>